import { VFC } from 'react'
import { HStack, Text, TextProps, VStack } from '@chakra-ui/react'
import { colors, fontSizes } from '@/libs/styles'
import { formatNumber } from '@/utils/string.util'
import { useResponsiveStylingObject } from '@/utils/style.util'

type InputExtensionItemProps = TextProps

const InputExtensionItem: VFC<InputExtensionItemProps> = ({
  children,
  fontSize = [fontSizes.tiny, fontSizes.little],
  textColor = colors.black[7],
  ...props
}) => {
  if (!children) {
    return <></>
  }

  return <Text {...{ ...props, fontSize, textColor }}>{children}</Text>
}

export type InputExtensionProps = {
  currentLength?: number
  maxLength?: number
  required?: boolean
  errors?: (string | undefined)[]
  text?: string | string[]
}

export const InputExtension: VFC<InputExtensionProps> = ({
  currentLength = 0,
  maxLength = 0,
  required = false,
  errors = [],
  text,
}) => {
  const errorFontSize = useResponsiveStylingObject<string>({
    mobile: fontSizes.tiny,
    desktop: fontSizes.little,
  })
  return (
    <VStack w="100%" spacing="0">
      <HStack justifyContent="flex-end" w="100%" spacing="8px">
        {!!maxLength && (
          <InputExtensionItem>
            {formatNumber(currentLength)}/{formatNumber(maxLength)}
          </InputExtensionItem>
        )}
        {!!required && (
          <InputExtensionItem textColor={colors.system.danger}>
            必須
          </InputExtensionItem>
        )}
        {!!text && typeof text === 'string' && (
          <InputExtensionItem>{text}</InputExtensionItem>
        )}
        {!!text && Array.isArray(text) && (
          <VStack spacing={0} alignItems="flex-end">
            {text.map((item, index) => (
              <InputExtensionItem key={`decoration-text-key--${index}`}>
                {item}
              </InputExtensionItem>
            ))}
          </VStack>
        )}
      </HStack>
      <VStack w="100%" alignItems="flex-end" spacing="0">
        {errors
          .filter((item): item is string => item !== undefined)
          .map((error, index) => (
            <Text
              key={`error-key-${index}`}
              fontSize={errorFontSize}
              textColor={colors.system.danger}
            >
              {error}
            </Text>
          ))}
      </VStack>
    </VStack>
  )
}
