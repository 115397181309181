import { useResponsiveStylingObject } from '@/utils/style.util'
import {
  ToastPositionWithLogical,
  useBreakpointValue,
  useToast as useToastBase,
  UseToastOptions,
} from '@chakra-ui/react'

export const useToast = (options?: UseToastOptions) => {
  const position = useBreakpointValue<ToastPositionWithLogical>(
    useResponsiveStylingObject<ToastPositionWithLogical>({
      mobile: 'top',
      desktop: 'top-right',
    })
  )

  return useToastBase({
    status: 'info',
    duration: 3000,
    isClosable: true,
    position,
    variant: 'solid',
    ...options,
  })
}
