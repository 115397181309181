import {
  TextInput,
  TextInputProps,
} from '@/components/atoms/forms/text-input.atom'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import {
  TextInputDecorator,
  TextInputDecoratorProps,
} from '@/components/molecules/forms/text-input-decorator.molecules'

type ControlledTextInputProps<T extends FieldValues> = Omit<
  TextInputProps,
  'value'
> &
  UseControllerProps<T> &
  Pick<TextInputDecoratorProps, 'label' | 'extension'> & {}

export const ControlledTextInput = <T extends FieldValues>({
  control,
  name,
  defaultValue,
  label,
  extension,
  ...textInputProps
}: ControlledTextInputProps<T>) => {
  const { field, fieldState } = useController<T>({
    name,
    control,
    defaultValue,
  })

  return (
    <TextInputDecorator
      label={label}
      extension={{
        ...extension,
        errors: [fieldState.error?.message ?? ''],
      }}
      w="100%"
    >
      <TextInput {...field} {...textInputProps} />
    </TextInputDecorator>
  )
}
