import { VStack, BoxProps, Grid, GridItem } from '@chakra-ui/react'
import { ReactElement, VFC } from 'react'
import {
  InputExtension,
  InputExtensionProps,
} from '@/components/atoms/forms/input-extension.atom'
import {
  InputLabel,
  InputLabelProps,
} from '@/components/atoms/forms/input-label.atom'
import { TextInput } from '@/components/atoms/forms/text-input.atom'
import { useResponsiveStylingObject } from '@/utils/style.util'

type InputSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11

export type TextInputDecoratorProps = BoxProps & {
  label?: InputLabelProps & {
    text: string
  }
  extension?: Omit<InputExtensionProps, 'currentLength'>
  children: ReactElement<typeof TextInput>
  inputSize?: InputSize
}

export const TextInputDecorator: VFC<TextInputDecoratorProps> = ({
  children,
  label,
  extension,
  inputSize = 9,
  ...boxProps
}) => {
  const colRepeatSize = 12
  const labelSize = colRepeatSize - inputSize
  return (
    <VStack alignItems="flex-end" spacing="2px" {...boxProps}>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
        alignItems="center"
        columnGap={useResponsiveStylingObject<string>({
          mobile: '8px',
          desktop: '16px',
        })}
        w="100%"
      >
        <GridItem colSpan={labelSize}>
          {!!label && (
            <InputLabel whiteSpace="nowrap" {...label}>
              {label.text}
            </InputLabel>
          )}
        </GridItem>

        <GridItem colSpan={inputSize}>{children}</GridItem>
      </Grid>
      <Grid
        templateRows="repeat(1, 1fr)"
        templateColumns="repeat(12, 1fr)"
        alignItems="center"
        columnGap={useResponsiveStylingObject<string>({
          mobile: '8px',
          desktop: '16px',
        })}
        w="100%"
      >
        <GridItem colSpan={labelSize}></GridItem>
        <GridItem colSpan={inputSize}>
          {!!extension && (
            <InputExtension
              {...extension}
              currentLength={children.props.value?.length || 0}
            ></InputExtension>
          )}
        </GridItem>
      </Grid>
    </VStack>
  )
}
